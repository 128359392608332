/*------------ VARIÁVEIS GLOBAIS PARA CORES ---------------*/

:root {
  --primary-color: #076338;
  --secondary-color: #5E9B1F;
  --tfs-new-green: #00FC55;
  --background-color: #EFEFEB;
  --focus-color: #E31E24;
  --focus-dark-color: #FE7F00;
  --text-color: #333;
  --success-color: #61A375;
  --light-secondary-color: #D0F0AF;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-expanded {

  display: flex;
  padding-left: 18rem;
  padding-top: 5rem;
  padding-right: 1rem ;
}

.container-collapsed {

  display: flex;
  padding-left: 8rem;
  padding-top: 5rem;
  padding-right: 1rem ;
}

.navbar{
  z-index: 1;
}