.card-wrapper{
    display: flex;
    position: relative;
    flex-direction: row;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15); 
    border-radius: 0.5rem;
    padding: 0;
    margin: 0;
}

.embaladora-card {
    background-color: white;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    padding: 1rem 1rem;
    padding-left: 1rem;  
    width: calc(100%);
    height: 5rem;    
  }
  
  .embaladora-card.expanded {
    height: auto; /* Expand to fit content when expanded */
  }
  
  .embaladora-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .status {
    color: #ffffff;
    padding: 0.5rem 0.5rem;
    border-radius: 0.2rem;
    font-weight: normal;
    font-size: small;
  }
  
  .status.on {
    background-color: var(--secondary-color); /* Green for 'On' */
  }
  
  .status.off {
    background-color: #dc3545; /* Red for 'Off' */
  }
  
  .expand-button {
    background-color: var(--light-secondary-color);
    color: var(--text-color);
    border: none;
    cursor: pointer;
    height: 5rem;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    padding: 0rem 0.5rem;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    
  }

  .expand-button-expanded {
    background-color: var(--light-secondary-color);
    color: var(--text-color);
    border: none;
    cursor: pointer;
    top: -1rem;
    height: 5rem;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    padding: 0rem 0.5rem;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
 
    
  }
  
  .embaladora-card-details {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    margin-top: 1rem;
    padding-top: 1rem;
  }
  
  .detail-item {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  .detail-item:last-child{
    margin-right: 0;
  }
  
  .icon {
    /* Style your icons here */
  }
  
  .info {
    margin-left: 0.5rem;
  }
  
  .error {
    color: #ffffff;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    font-weight: normal;
    font-size: small;
    background: var(--secondary-color);
  }
  
  .error.has-error {
    background-color: #ff0000; /* Red for error */
  }
  