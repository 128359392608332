.dashboard-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.card-item-number{
    display: flex; 
    height: 5rem;
    width: 5rem;
    background: var(--secondary-color);
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);   
}

.machine-wrapper{
    display: flex;
    flex-direction: row;
}
