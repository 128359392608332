.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(204, 204, 204, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .modal-content {
    width: 20rem;
    height: 17rem;
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: var(--primary-color);
  }

  .modal-logo{
    color: var(--primary-color);
    margin-bottom: 2rem;
  }

  .modal-cancel-btn{
    display: flex;
    position: relative;
    justify-content: right;
    top: -3.3rem;
    right: 0rem;
  }

  .modal-cancel-btn-button{
    background: var(--primary-color);
    color: white;
    width: 2.5rem;
    height: 1.5rem;
    padding: 0.1rem;
    border-radius: 2rem;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    position: relative;
  }
  
  .modal-buttons-wrapper {
    margin-top: 10px;
}

.modal-buttons-wrapper button {
    border: none;
    border-radius: 1.5rem;
    margin: 0 5px;
    padding: 0.7rem;
    width: 6rem;
    color: white;
    cursor: pointer;
}

.btn-ok{
    background: var(--secondary-color);
    cursor: pointer;
}

.btn-no{
    background: var(--text-color);
    cursor: pointer;
}

.btn-no:hover, .btn-ok:hover{
    transform: scale(1.05);
}
  
  