* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .sidebar {
    position: fixed;
    top: 1rem;
    left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 240px;
    height: 95%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);   
    z-index: 10;      
    background: white;
    color: gray;  
    border-radius: 1rem;        
  }

  .sidebar.collapsed{
    width: 82px;
  }
  .sidebar.collapsed .sidebar-icon{
    margin-left: -1rem;
  }

  .sidebar.collapsed.sidebar-text,
  .sidebar.collapsed .sidebar-logo{
    display: none;
  }

.sidebar-logo{
    font-size: 16px;
    margin-bottom: 2rem;
    
}

.sidebar-icon{
    margin-right: 1rem;
    cursor: pointer;
    user-select: none;
}

.sidebar-header{
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.sidebar-items{
    display: flex;
    flex-direction: column;
    text-decoration: none;
    margin: 0;
    padding: 0;    
    height:fit-content;
    width: 100%;
    z-index: 3;
    animation: 0.5s ease-out 0s 1 slideInFromLeft;   
    border-top: 1px solid rgb(221, 221, 221);   
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    

}

.sidebar-items .item{
    display: flex;
    flex-direction: row;
    align-items: center;
       border-radius: 5px;
    transition: background-color 0.2s;
    cursor: pointer;
}

.sidebar-items .item:hover{
    background-color: var(--primary-color);
}


  .sidebar::-webkit-scrollbar {
    display: none;
  }
  
  .logo-before {
   height: 2rem;
    background: var(--primary-color);
  }
  
  .logo {
   
    height: 1.5rem;
    background: var(--background-color);
    border-radius: 0px 25px 0px 0px;
    margin-top: -1.4rem;
   
    z-index: 15;
 }

 .logo-after{
   
    height: 2rem;
    cursor: pointer;
    
 }
 .logo-after:hover{
    color: var(--primary-color);
 }
 
  .logo-center{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-bottom: 2rem;
    transition: all 0.2s ease;
    
  }
  .logo-xl{
    width: 150px;
    animation: 0.5s ease-in-out 0s 1 slideInFromLeft; 
    animation-delay: 0s, 0.5s;
    

  }
  .sidebar-menu {
    border-radius: 0px 50px 0px 0;
    background: none;
    height: 100%;
    margin-top: -2.5rem;
    padding-top: 4rem;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
   
    
  }
  
  
  
  .sidebarList {
    margin: 0;
    padding: 0;
    
    height:fit-content;
    width: 100%;
    z-index: 3;
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
    overflow-y: scroll;
   
  
  }
  
  .sidebarList::-webkit-scrollbar {
    display: none;
  }

  
  
  .sidebarList .row {
    border-top: 1px solid rgb(221, 221, 221);
    margin: 0;
    width: 100%;
    height: 4rem;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    transition: all 0.2s ease;
    padding-left: 3rem;
  }
  .sidebarList :first-child{
    border-top: none;
    
  }
  
  .sidebarList .row.active {
    background: #e7e6e6;
    color: var(--primary-color);
    border-left: 12px solid var(--primary-color);
  }
  
  .sidebarList .row:hover {
    background: #ffffff;
    border-left: 12px solid var(--primary-color);
    color: var(--primary-color);
    cursor: pointer;
    box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);

  }
  
  .sidebar-icon {
   
    display: grid;
    place-items: center;
    justify-content: right;
    color: black;
  }
  .row.active .sidebar-icon {
    color: var(--primary-color);
  }
  
  .sidebar-text {
    flex: 75%;
    text-align: left;
    font-size: small;
    color: #767676;
    padding-left: 10px;
  }

  .row-item{
    
   
    display: flex;
  }
  .row #title:active{
    color:var(--primary-color)
  }
  
  .sidebarList-exit {
   width: 100%;
    list-style-type: none;
    padding-top: 5rem;
    margin-top: 0rem;
    text-align: center;
    justify-content: center;
    border-top: 1px solid rgb(221, 221, 221);
    
   
  }
  
  .exit {
    display: inline-flex;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50px 50px;
    color: white;
    justify-content: center;
    place-items: center;   
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
    color: var(--text-color);
    margin-bottom: 0.6rem;
    
  }
  
  
  .exit:hover {
    background: var(--background-color);
    cursor: pointer;
    box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.25);
    color: var(--primary-color);
  }
  
  .block{
    display: block;
  }
  
  .hidden{
    display: none;
  }
  
  
  /* @media only screen and (min-width: 768) {
  
    .sidebar{
      display: none;
    }
   
   
  }
  
  
  @media only screen and (max-width: 769) {
  
    .sidebar{
      display: block;
    }
   
   
  } */

  .sidebar-content{
    margin: 0;
    padding: 0;
    height: 100vh;
    box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.15);
  }

  .toggle{
    position: relative;
   
    display: block;
    background: none;
    overflow: visible;
    color: var(--primary-color);
  
   
   
  }

  .menu-item{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: left;
    border-top: 1px solid rgb(221, 221, 221);
  }

  .menu-item {
    position: relative;
  }
  
  .logo-toggler{
    display: flex;
    text-align: right;
    margin-right: 0.9rem;
    justify-content: flex-end;
    position: relative;
    top: -1.5rem;
    right: 0.5rem;
}

.logo-toggler-btn{
    color: white;
    background: var(--background-color);
    width: 2rem;
    height: 2rem;
    padding: 0.3rem;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.logo-toggler-btn:hover{
    background: var(--primary-color);
}