.login-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.card-login{
    background: white;
    width: 16rem;
   
    border-radius: 1rem;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);
}

.card-login-header{
    margin-bottom: 0rem;
}
.card-login-body{
    background: black;
    padding: 1rem 1rem;
    border-radius: 0rem 0rem 1rem 1rem;
}
.login-input-group {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
  
.login-input-group-item{
    width: 12rem;
    border: none;
    padding: 0.5rem;
    color: var(--secondary-color);     
    border-radius: 0.2rem;
    font-size: small;
}

  
input:focus {
    border-color: var(--secondary-color); 
    outline: 1px; 
    box-shadow: 0 0 0 2px var(--secondary-color);
}  

.button-wrapper{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 0rem 1rem ;
    margin-bottom: 1rem;
}
  
.default-button {
    background-color: var(--secondary-color);
    color: white;
    padding: 10px;
    border: none;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    font-size: small;
}

.default-button:hover{
    transform: scale(1.01);
    background: var(--focus-color);
}

.error-message-wrapper{
    padding: 1.1rem;
}
.error-message{
    background: var(--focus-color);
    color: white;
    padding: 0.3rem 0.3rem;
    font-size: x-small;
    border-radius: 0.3rem
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }